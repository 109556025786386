import { gql, ExecutionResult } from 'apollo-boost'
import { round } from 'lodash'
import { CENTS } from '../constants'
import client from './client'

export const checkout = ({
  projectId,
  bookingId,
  lineItems,
  donation,
  openPriceInCents
}: {
  projectId: string
  bookingId: string
  lineItems: {
    price: string
    quantity: number
    productName: string
  }[]
  donation?: number
  openPriceInCents?: number
}): Promise<ExecutionResult<any>> => {
  return client.mutate({
    mutation: gql`
      mutation checkout(
        $projectId: ID!
        $bookingId: ID!
        $lineItems: [CheckoutLineItem!]!
        $donation: Float
        $openPriceInCents: Int
      ) {
        checkout(
          projectId: $projectId
          bookingId: $bookingId
          lineItems: $lineItems
          donation: $donation
          openPriceInCents: $openPriceInCents
        ) {
          sessionId
          url
        }
      }
    `,
    variables: {
      projectId,
      bookingId,
      lineItems,
      donation: round(parseFloat(String(donation) || '0') * 100, CENTS),
      openPriceInCents: (openPriceInCents || 0) * 100
    }
  })
}

export const completeOrder = ({
  projectId,
  bookingId,
  orderNumber
}: {
  projectId: string
  bookingId: string
  orderNumber: string
}): Promise<ExecutionResult<any>> => {
  return client.mutate({
    mutation: gql`
      mutation completeOrder(
        $projectId: ID!
        $orderNumber: ID!
        $bookingId: ID!
      ) {
        completeOrder(
          projectId: $projectId
          orderNumber: $orderNumber
          bookingId: $bookingId
        ) {
          success
          message
        }
      }
    `,
    variables: { projectId, bookingId, orderNumber }
  })
}
