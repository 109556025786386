import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { ApolloProvider } from '@apollo/react-hooks'
import { Login } from './containers/Login'
import './locales/i18n'
import * as serviceWorker from './serviceWorker'
import { ROUTES, BUMBAG_THEME } from './constants'
import { Provider as BumbagProvider } from 'bumbag'
import './index.css'
import client from './sdk/client'
import { AppProvider } from './context/App'
import { styled, Spinner } from 'bumbag'
import { StoreContainer } from './containers/Order/Store'
import { BookingContainer } from './containers/Order/Booking'
import { Summary as SummaryContainer } from './containers/Summary'
import CompletedContainer from './containers/Completed/Completed'
import ErrorContainer from './containers/Error'

import { useAppContext } from './context/App'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV
  })
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const App = () => {
  const { loading, showError, project } = useAppContext()

  return (
    <AppProvider>
      <Container>
        {loading && <Spinner color="primary" size="large" />}
        {/* {showError && <ErrorContainer />} */}
        {!loading && !showError && project && (
          <Switch>
            <Route path={ROUTES.order} component={StoreContainer} exact />
            <Route path={ROUTES.booking} component={BookingContainer} exact />
            <Route path={ROUTES.summary} component={SummaryContainer} exact />
            <Route
              path={ROUTES.completed}
              component={CompletedContainer}
              exact
            />
            <Route component={ErrorContainer} />
          </Switch>
        )}
      </Container>
    </AppProvider>
  )
}

function Index() {
  return (
    <BumbagProvider theme={BUMBAG_THEME}>
      <ApolloProvider client={client}>
        <React.StrictMode>
          <Router>
            <Switch>
              <Route path={ROUTES.login} component={Login} exact />
              <Route path={ROUTES.app} exact>
                <AppProvider>
                  <App />
                </AppProvider>
              </Route>
              <Route path={ROUTES.booking} exact>
                <AppProvider>
                  <App />
                </AppProvider>
              </Route>
              <Route path={ROUTES.summary} exact>
                <AppProvider>
                  <App />
                </AppProvider>
              </Route>
              <Route path={ROUTES.completed} exact>
                <AppProvider>
                  <App />
                </AppProvider>
              </Route>
              <Route component={ErrorContainer} />
            </Switch>
          </Router>
        </React.StrictMode>
      </ApolloProvider>
    </BumbagProvider>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
