import { gql, ApolloQueryResult } from 'apollo-boost'
import client from './client'

export const getData = ({
  projectId,
  storeCode
}: {
  projectId: string
  storeCode: string
}): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: gql`
      query getProject($projectId: ID!, $storeCode: ID!) {
        findProject(projectId: $projectId) {
          projectId
          stripePublishableKey
          currency
        }
        findSite(projectId: $projectId, storeCode: $storeCode) {
          siteId
          storeCode
          name
          phone
          timezone
        }
        findBookingsForToday(projectId: $projectId, storeCode: $storeCode) {
          bookingId
          projectId
          storeCode
          vendorId
          start
          end
          productIds
          name
          isRedRun
          eventType
          name
          vendor {
            name
            altName
            taxId {
              value
              type
            }
            isCharity
          }
          donationsEnabled
          purchaseCap
        }
        findProducts(projectId: $projectId) {
          data {
            id
            name
          }
        }
        findPrices(projectId: $projectId) {
          data {
            id
            product
            active
            currency
            unit_amount
          }
        }
      }
    `,
    variables: { projectId, storeCode },
    fetchPolicy: 'network-only'
  })
}

export const getOrder = ({
  projectId,
  bookingId,
  orderNumber
}: {
  projectId: string
  bookingId: string
  orderNumber: string
}): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: gql`
      query getOrder($projectId: ID!, $bookingId: ID!, $orderNumber: ID!) {
        findOrder(
          projectId: $projectId
          bookingId: $bookingId
          orderNumber: $orderNumber
        ) {
          orderNumber
          orderDate
          orderStatus
          orderAmount
          checkoutSessionId
          stripeReceiptUrl
          successUrl
          vendorId
          projectId
          bookingId
          refundId
          refundAmount
          refundStatus
          items {
            itemId
            totalAmount
            unitAmount
            quantity
            name
            currency
            productId
          }
          donation
        }
      }
    `,
    variables: { projectId, bookingId, orderNumber },
    fetchPolicy: 'network-only'
  })
}

export const getProducts = ({
  projectId
}: {
  projectId: string
}): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: gql`
      query getProducts($projectId: ID!) {
        findProducts(projectId: $projectId) {
          data {
            id
            name
          }
        }
      }
    `,
    variables: { projectId },
    fetchPolicy: 'network-only'
  })
}
