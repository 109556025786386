import { find, get } from 'lodash'
import { COUNTRIES, STORE_CODE_MAPPING, PRIVACY_LINKS } from '../constants'

const getCountryByStoreCode = (storeCode: string) =>
  get(find(STORE_CODE_MAPPING, { projectStoreCode: storeCode }), 'country')

type Country = 'au' | 'nz'

export const getCountryByProjectId = (projectId: string): Country => {
  if (projectId === process.env.REACT_APP_PROJECT_ID_AU) {
    return 'au'
  }
  return 'nz'
}

export const getProjectIdByStoreCode = (storeCode: string) => {
  if (storeCode) {
    const country = getCountryByStoreCode(storeCode)
    if (country === COUNTRIES.nz) {
      return process.env.REACT_APP_PROJECT_ID_NZ
    }
    return process.env.REACT_APP_PROJECT_ID_AU
  }
  return ''
}

export const getPrivacyByStoreCode = (storeCode: string) => {
  if (storeCode) {
    const country = getCountryByStoreCode(storeCode)
    if (country === COUNTRIES.nz) {
      return PRIVACY_LINKS.nz
    }
    return PRIVACY_LINKS.au
  }
  return PRIVACY_LINKS.au
}
