import * as Sentry from '@sentry/browser'

export const setupSentryContext = (storeCode: string) => {
  Sentry.configureScope((scope) => {
    scope.setTag('projectId', process.env.REACT_APP_PROJECT_ID!)
    scope.setTag('storeCode', storeCode)
  })
}

export const sendSentryWarning = (message: string) => {
  Sentry.withScope(() => {
    Sentry.captureMessage(message, Sentry.Severity.Warning)
  })
}

export const sendSentryError = (message: string) => {
  Sentry.withScope((scope) => {
    scope.setLevel(Sentry.Severity.Error)
    Sentry.captureException(new Error(message))
  })
}
