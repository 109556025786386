import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import CodeInput from 'react-code-input'
import { Header } from '../../components/Header'
import Privacy from '../../components/Privacy'
import {
  Container,
  Body,
  Content,
  Text,
  StyledButton,
  Warning
} from './styled-components'

export const Login = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [storeCode, setStoreCode] = useState<string>('')

  const handleClick = () => {
    history.push(`/${storeCode}`, { login: true })
  }

  return (
    <Container>
      <Header hideMenu />
      <Body>
        <Content>
          <Text>{t('login.title')}</Text>
          <CodeInput
            type="text"
            inputMode="numeric"
            name="LoginCodeInput"
            fields={4}
            inputStyle={{
              width: '3rem',
              height: '3rem',
              borderRadius: '0.125rem',
              color: '#0D5257',
              border: '1px solid #0D5257',
              textAlign: 'center',
              margin: '0.5rem',
              fontWeight: 'bold',
              fontSize: '1.25rem'
            }}
            // whitelisting is not working
            // blacklists everything that is not the keycodes of 0-9
            filterKeyCodes={Array.from(Array(300).keys()).filter(
              (k) => ![48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(k)
            )}
            filterChars={['!', '@', '#', '$', '%', '^', '&', '*', '(', ')']}
            value={storeCode}
            onChange={(code: string) => {
              setStoreCode(code)
            }}
          />
          <Warning>
            {get(location, 'state.error') && !storeCode
              ? t('error.invalidStoreCode')
              : ''}
          </Warning>
        </Content>

        <StyledButton
          palette="primary"
          onClick={handleClick}
          disabled={!storeCode || storeCode.length !== 4}
        >
          {t('login.buttonText')}
        </StyledButton>
        <Privacy />
      </Body>
    </Container>
  )
}
