import React from 'react'
import { Dialog, ModalProps, Button, styled, Spinner } from 'bumbag'

import { useTranslation } from 'react-i18next'

export const StyledSpinner = styled(Spinner)`
  position: relative;
  top: 0.25rem;
`
export const ConfirmModal = ({
  modal,
  title,
  loading,
  onSubmit
}: {
  onSubmit: () => Promise<void>
  modal: ModalProps
  title: string
  loading: boolean
}) => {
  const { t } = useTranslation()

  const handleSubmit = async () => {
    await onSubmit()
    modal.hide?.()
  }

  return (
    <Dialog.Modal {...modal} hideOnClickOutside={!loading} textAlign="center">
      <Dialog.Title fontSize="200" color="black" fontWeight="normal">
        {title}
      </Dialog.Title>
      <Dialog.Content display="flex" flexDirection="column" padding="1rem">
        <Button
          palette="primary"
          onClick={handleSubmit}
          disabled={loading}
          marginBottom="1rem"
        >
          {loading ? <StyledSpinner color="white" /> : t('summary.modal.yes')}
        </Button>
        <Button onClick={modal.hide} color="#414142">
          {t('summary.modal.no')}
        </Button>
      </Dialog.Content>
    </Dialog.Modal>
  )
}
