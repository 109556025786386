import { gql } from 'apollo-boost'

export const ORDER_UPDATE_SUBSCRIPTION = gql`
  subscription orderUpdated(
    $projectId: ID!
    $bookingId: ID!
    $orderNumber: ID!
  ) {
    orderUpdated(
      projectId: $projectId
      bookingId: $bookingId
      orderNumber: $orderNumber
    ) {
      orderNumber
      topic
    }
  }
`
