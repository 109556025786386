import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Privacy from '../../components/Privacy'
import { useAppContext } from '../../context/App/AppContext'
import { NON_SIZZLE_EVENT_TYPE, SAUSAGE_SIZZLE_EVENT } from '../../constants'
import { Container, NoBooking, Message } from './styled-components'
import { NoneSizzleEvent } from './NonSizzleEvent'
import { SizzleContainer } from './SizzleEvent'

export function BookingContainer() {
  const { bookings } = useAppContext()
  const { bookingId } = useParams<{
    storeCode: string
    bookingId: string
  }>()
  const { t } = useTranslation()

  const selectedBooking = bookings
    ? bookings?.find((bookings) => bookings.bookingId === bookingId)
    : null

  return (
    <Container>
      {!selectedBooking && (
        <NoBooking>
          <Message>{t('order.noBooking')}</Message>
          <Privacy />
        </NoBooking>
      )}

      {selectedBooking &&
        selectedBooking.eventType === NON_SIZZLE_EVENT_TYPE && (
          <NoneSizzleEvent />
        )}

      {selectedBooking &&
        selectedBooking.eventType === SAUSAGE_SIZZLE_EVENT && (
          <SizzleContainer />
        )}
    </Container>
  )
}
