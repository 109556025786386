export type TProject = {
  projectId: string
  stripePublishableKey: string
  currency: string
}

export type TPrice = {
  currency: string
  id: string
  product: string
  unit_amount: number
  active: boolean
}

export type TProduct = {
  description: string
  id: string
  images: string[]
  name: string
  prices?: TPrice[]
}

export type TOrder = {
  quantity: number
  productId: string
  productName: string
  priceId: string
  unit_amount: number
}[]

export type TSite = {
  name: string
  phone: string
  siteId: string
  storeCode: string
  timezone: string
}

export type TBooking = {
  bookingId: string
  end: string
  name: string
  productIds: string[]
  projectId: string
  start: string
  storeCode: string
  eventType: string
  vendor: {
    name: string
    altName?: string
    taxId?: {
      value: string
      type: 'au_abn' | 'nz_nzbn'
    }
    isCharity?: boolean
  }
  vendorId: string
  donationsEnabled: boolean
  purchaseCap: number
  isRedRun?: boolean
}

export type TOrderItem = {
  itemId: string
  totalAmount: number
  unitAmount: number
  quantity: number
  name: string
  currency: string
  productId: string
}

export type TOrderDetails = {
  orderNumber: string
  orderDate: Date
  orderStatus: string
  orderAmount: number
  checkoutSessionId: string
  stripeReceiptUrl: string
  successUrl: string
  vendorId: string
  projectId: string
  items: TOrderItem[]
  bookingId: string
  donation: number
}

export enum TStep {
  events,
  order,
  donations
}
