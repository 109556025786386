import { reduce, isEmpty, find, filter, get, isNil, omit } from 'lodash'
import dayjs from 'dayjs'
import { TOrder, TProduct } from '../types'
import {
  LOCAL_STORAGE_ORDER,
  LOCAL_STORAGE_EXPIRATION_TIME
} from '../constants'
import { sendSentryError } from './sentry'
import { getProductById } from './product'

export const removeOrderFromLocalStorage = ({
  bookingId
}: {
  bookingId: string
}) => {
  localStorage.removeItem(`${bookingId}__${LOCAL_STORAGE_ORDER}`)
}

export const getDonationsFromLocalStorage = ({
  bookingId
}: {
  bookingId: string
}) => {
  const orderInLocalStorage = localStorage.getItem(
    `${bookingId}__${LOCAL_STORAGE_ORDER}`
  )
  const { donation } = orderInLocalStorage
    ? JSON.parse(orderInLocalStorage)
    : { donation: null }
  return donation
}

export const removeDonationFromLocalStorage = ({
  bookingId
}: {
  bookingId: string
}) => {
  const orderInLocalStorage = localStorage.getItem(
    `${bookingId}__${LOCAL_STORAGE_ORDER}`
  )
  const order = orderInLocalStorage ? JSON.parse(orderInLocalStorage) : {}

  localStorage.setItem(
    `${bookingId}__${LOCAL_STORAGE_ORDER}`,
    JSON.stringify(omit(order, 'donation'))
  )
}

export const getStateFromLocalStorage = ({
  bookingId
}: {
  bookingId: string
}) => {
  const state = localStorage.getItem(`${bookingId}__${LOCAL_STORAGE_ORDER}`)
  return state ? JSON.parse(state) : null
}

interface UpdateStorage {
  storeCode: string
  order?: TOrder
  timestamp?: Date
  donation?: number
  bookingId: string
}
export const updateLocalStorageState = (updatedState: UpdateStorage) => {
  const {
    order,
    donation,
    storeCode,
    timestamp = new Date(),
    bookingId
  } = updatedState
  const cached = getStateFromLocalStorage({ bookingId })

  if (!bookingId) {
    return
  }

  const payload = {
    ...cached,
    storeCode,
    timestamp,
    ...(!isEmpty(order) && { products: order }),
    ...(!isNil(donation) && { donation })
  }

  localStorage.setItem(
    `${bookingId}__${LOCAL_STORAGE_ORDER}`,
    JSON.stringify(payload)
  )
}

export const getOrderFromLocalStorage = ({
  productsWithPrice,
  storeCode,
  bookingId
}: {
  productsWithPrice: TProduct[]
  storeCode: string
  bookingId: string
}) => {
  const key = `${bookingId}__${LOCAL_STORAGE_ORDER}`

  const orderInLocalStorage = localStorage.getItem(key)

  try {
    if (!isEmpty(orderInLocalStorage)) {
      const {
        timestamp,
        storeCode: storeCodeInLocalStorage,
        products: productsInLocalStorage
      } = JSON.parse(orderInLocalStorage!)
      if (
        storeCodeInLocalStorage !== storeCode ||
        // @ts-ignore
        dayjs().diff(timestamp, 'hour') > LOCAL_STORAGE_EXPIRATION_TIME
      ) {
        removeOrderFromLocalStorage({ bookingId })
        return []
      }
      return reduce(
        productsInLocalStorage,
        (
          order: TOrder,
          { productId, quantity }: { productId: string; quantity: number }
        ) => {
          const product = getProductById({
            id: productId,
            products: productsWithPrice
          })
          if (!isEmpty(product)) {
            const price = find(get(product, 'prices', []), {
              product: product!.id
            })
            if (!isEmpty(price)) {
              order.push({
                productId,
                quantity,
                productName: product!.name,
                priceId: price!.id,
                unit_amount: price!.unit_amount
              })
            }
          }
          return order
        },
        []
      )
    }
    return []
  } catch {
    sendSentryError('Failed to get order from local storage')
    return []
  }
}

export const getTotalPrice = (order: TOrder = [], donation?: number) => {
  let totalPrice = 0
  if (!isEmpty(order)) {
    totalPrice +=
      reduce(
        order,
        (total, { quantity, unit_amount }) => (total += quantity * unit_amount),
        0
      ) / 100
  }
  totalPrice += donation ?? 0
  return totalPrice
}

export const getTotalItems = (order: TOrder) =>
  isEmpty(order)
    ? 0
    : reduce(order, (total, { quantity }) => (total += quantity), 0)

export const getProductFromOrderById = ({
  order,
  id
}: {
  order?: TOrder
  id: string
}) => {
  return find(order, { productId: id })
}

export const getLineItems = (order: TOrder) => {
  if (isEmpty(order)) {
    return []
  }
  return filter(order, ({ quantity }) => quantity > 0).map(
    ({ priceId, quantity, productName }) => ({
      price: priceId,
      quantity,
      productName
    })
  )
}
