import React, { useState } from 'react'
import { styled, Button, Spinner } from 'bumbag'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../context/App/AppContext'
import { getTotalItems } from '../../utils/order'
import OrderDetails from './OrderDetails'

const StyledButton = styled(Button)`
  height: 3rem;
  width: 100%;
  margin-top: 0.5rem;
  color: #fff;
`

const StyledSpinner = styled(Spinner)`
  position: relative;
  top: 0.25rem;
`
const OrderDetailsWithPay = ({
  onClick,
  reachLimit,
  checkoutLimit
}: {
  onClick: Function
  reachLimit: boolean
  checkoutLimit: number
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { order, donation, totalPrice } = useAppContext()

  const handleClick = async () => {
    setIsLoading(true)
    await onClick()
    setIsLoading(false)
  }
  return (
    <OrderDetails
      reachLimit={reachLimit}
      totalPrice={Number(totalPrice)}
      checkoutLimit={checkoutLimit}
    >
      <StyledButton
        palette="primary"
        disabled={
          (getTotalItems(order!) === 0 && !donation) || reachLimit || isLoading
        }
        onClick={handleClick}
      >
        {isLoading ? (
          <StyledSpinner color="white" />
        ) : (
          t('order.orderNowButtonText')
        )}
      </StyledButton>
    </OrderDetails>
  )
}

export default OrderDetailsWithPay
