import React, { useEffect, useCallback, useState } from 'react'
import { Modal, Spinner } from 'bumbag'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { get } from 'lodash'
import qs from 'qs'
import { Header } from '../../components/Header'
import OrderCard from '../../components/summary/OrderCard'
import Instruction from '../../components/summary/Instruction'
import Privacy from '../../components/Privacy'
import Progress from '../../components/Progress'
import { removeOrderFromLocalStorage } from '../../utils/order'
import { sendSentryError } from '../../utils/sentry'
import { TOrderDetails } from '../../types'
import { useAppContextActions } from '../../context/App/AppContext'
import { ORDER_STATUS, REFUND_STATUS } from '../../constants'
import * as sdk from '../../sdk'
import {
  Container,
  Body,
  StyledButton,
  GhostButton,
  StyledLink,
  Footer
} from './styled-components'
import { MistakeModal, ConfirmModal } from '../../components/modals'

export const Summary = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { storeCode, bookingId } = useParams<{
    storeCode: string
    bookingId: string
  }>()
  const { setOrder, setShowError, setDonation } = useAppContextActions()

  const [orderDetails, setOrderDetails] = useState<TOrderDetails | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const orderNumber = get(queryParams, 'orderNumber', '') as string
  const projectId = get(queryParams, 'projectId', '') as string

  const confirmModal = Modal.useState()
  const mistakeModal = Modal.useState()

  const getOrder = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await sdk.getOrder({ orderNumber, bookingId, projectId })
      const order = get(res, 'data.findOrder')
      if (
        get(order, 'orderStatus') === ORDER_STATUS.completed ||
        get(order, 'refundStatus') === REFUND_STATUS.succeeded
      ) {
        history.push(
          `/${storeCode}/${bookingId}/completed?orderNumber=${orderNumber}&projectId=${projectId}&bookingId=${bookingId}`
        )
        return
      }

      if (order?.donation && !order?.items?.length) {
        await sdk.completeOrder({
          orderNumber,
          projectId,
          bookingId: get(order, 'bookingId', '')
        })
        history.push(
          `/${storeCode}/${bookingId}/completed?orderNumber=${orderNumber}&projectId=${projectId}&bookingId=${bookingId}`
        )

        return
      }

      setOrderDetails(order)

      setIsLoading(false)
      setShowError(false)
    } catch (e) {
      sendSentryError(`Failed to get order (summary) - ${JSON.stringify(e)}`)
      setIsLoading(false)
      setShowError(true)
    }
  }, [orderNumber, bookingId, projectId, history, storeCode, setShowError])

  useEffect(() => {
    setOrder([])
    setDonation(undefined)
    getOrder()
    removeOrderFromLocalStorage({
      bookingId
    })
  }, [getOrder, setDonation, setOrder, bookingId])

  const handleClick = async () => {
    try {
      setIsFetching(true)
      await sdk.completeOrder({
        orderNumber,
        projectId,
        bookingId: get(orderDetails, 'bookingId', '')
      })
      setIsFetching(false)
    } catch (e) {
      setIsFetching(false)
      sendSentryError(`Failed to complete order - ${JSON.stringify(e)}`)
      setShowError(true)
    }
  }

  if (isLoading) {
    return (
      <Spinner color="primary" size="large" data-testid="summary-spinner" />
    )
  }

  return (
    <Container>
      <Header hideMenu />
      <Body>
        <Progress value={100} stage={2} />
        <OrderCard order={orderDetails} />
        <Instruction />

        <StyledButton
          palette="primary"
          color="#fff"
          onClick={() => {
            history.push(`/${storeCode}?src=reorder`)
          }}
        >
          {t('completed.orderAgainButtonText')}
        </StyledButton>

        <Footer>
          <GhostButton variant="ghost">
            <StyledLink
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=ziMh2eoOmkiFyrtOPcUejcFAEzL2l_9Bj3qke5M9SgxUMkhaQ01FU1kwUFdHMDZOSVlRQzlaUDFEUiQlQCN0PWcu"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('completed.feedbackButtonButtonText')}
            </StyledLink>
          </GhostButton>

          <GhostButton variant="ghost" onClick={mistakeModal.show}>
            {t('summary.mistakeButtonText')}
          </GhostButton>
        </Footer>

        <Privacy />
        <ConfirmModal
          modal={confirmModal}
          title={t('summary.modal.collectText')}
          onSubmit={handleClick}
          loading={isFetching}
        />
        <MistakeModal
          modal={mistakeModal}
          title={t('summary.modal.refundText')}
        />
      </Body>
    </Container>
  )
}
