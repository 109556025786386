import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Header } from '../../components/Header'
import Privacy from '../../components/Privacy'
import {
  useAppContext,
  useAppContextActions
} from '../../context/App/AppContext'
import { Container, NoBooking, Message } from './styled-components'
import { EventSelector } from './EventSelector'
import { useParams } from 'react-router-dom'
import { Flex, Spinner } from 'bumbag'

export function StoreContainer() {
  const { bookings } = useAppContext()
  const { storeCode } = useParams<{
    storeCode: string
  }>()
  const { setOrder, setBooking } = useAppContextActions()
  const { t } = useTranslation()

  useEffect(() => {
    if (bookings?.length && bookings.length === 1) {
      const { bookingId } = bookings[0]

      window.location.href = `${window.location.origin}/${storeCode}/${bookingId}`
    }
  }, [bookings, storeCode])

  return (
    <Container>
      <Header />
      {isEmpty(bookings) && (
        <NoBooking>
          <Message>{t('order.noBooking')}</Message>
          <Privacy />
        </NoBooking>
      )}
      {bookings && bookings?.length === 1 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <Spinner color="primary" size="large" />
        </Flex>
      )}
      {bookings && bookings?.length > 1 && (
        <EventSelector
          bookings={bookings}
          setBooking={setBooking}
          setOrder={setOrder}
        />
      )}
    </Container>
  )
}
