import React from 'react'
import { Dialog, ModalProps, Button } from 'bumbag'

import { useTranslation } from 'react-i18next'

export const MistakeModal = ({
  modal,
  title
}: {
  modal: ModalProps
  title: string
}) => {
  const { t } = useTranslation()
  return (
    <Dialog.Modal {...modal} hideOnClickOutside={true} textAlign="center">
      <Dialog.Title fontSize="200" color="black" fontWeight="normal">
        {title}
      </Dialog.Title>
      <Dialog.Content display="flex" flexDirection="column" padding="1rem">
        <Button onClick={modal.hide} palette="primary">
          {t('summary.modal.ok')}
        </Button>
      </Dialog.Content>
    </Dialog.Modal>
  )
}
