import React from 'react'
import { Button, Icon } from 'bumbag'
import { useTranslation } from 'react-i18next'
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons'
import { CENTS } from '../../../constants'
import { useAppContext } from '../../../context/App'

export const DonationButton = ({
  donation,
  show,
  onClick
}: {
  onClick: () => void
  donation?: number
  show?: boolean
}) => {
  const { donationOrGift } = useAppContext()

  const { t } = useTranslation()
  if (!show) return null
  if (donation) {
    return (
      <Button
        palette="yellow"
        margin="1rem"
        color="black"
        onClick={onClick}
        display="flex"
        position="relative"
      >
        <div>
          {t('currencySymbol')}
          {donation.toFixed(CENTS)} {t(`${donationOrGift}.button.added`)}
        </div>
        <Icon
          right={'15px'}
          position="absolute"
          aria-label="add-donation"
          icon={faPen}
          type="font-awesome"
        />
      </Button>
    )
  }
  return (
    <Button palette="yellow" margin="1rem" color="black" onClick={onClick}>
      <Icon
        aria-label="add-donation"
        icon={faPlus}
        type="font-awesome"
        marginRight="0.5rem"
      />
      {t(`${donationOrGift}.button.addOne`)}
    </Button>
  )
}
