import React from 'react'
import { styled } from 'bumbag'
import { useTranslation } from 'react-i18next'
import { Header } from '../components/Header'
import ErrorIcon from '../components/ErrorIcon'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 10;
`
const Body = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex-direction: column;
`

const Text = styled.div`
  margin-top: 1rem;
  font-size: 1.1875rem;
  font-weight: bold;
  text-align: center;
`

const ErrorView = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Header hideMenu />
      <Body>
        <ErrorIcon />
        <Text>{t('error.generic')}</Text>
      </Body>
    </Container>
  )
}

export default ErrorView
