import React, { useState } from 'react'
import { styled, palette, Button, Box, Flex, Heading, Text } from 'bumbag'
import { get } from 'lodash'
import { useAppContext } from '../context/App/AppContext'
import SausageSizzleLogo from './logos/SausageSizzleLogo'
import BurgerIcon from './BurgerIcon'
import CloseIcon from './CloseIcon'
import { Link, useParams } from 'react-router-dom'
import { SAUSAGE_SIZZLE_EVENT } from '../constants'

const Title = styled.div`
  font-weight: 900;
  text-transform: capitalize;
  text-align: left;
`
const SubTitle = styled.div`
  text-transform: capitalize;
  text-align: left;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`
const Container = styled.div`
  width: 100%;
  display: flex;
  color: #fff;
  align-items: center;
  padding: 1rem;
  background-color: ${palette('secondary')};
  flex-shrink: 0;
`

const MenuWrapper = styled.div<{
  open: boolean
}>`
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  background: white;
  position: fixed;
  top: 90px;
  padding: 1rem;
  width: 80%;
  max-width: 350px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  right: ${(props) => (props.open ? 0 : '-100%')};
  bottom: 0;
  z-index: 100;
`

function Menu({ open }: { open: boolean }) {
  const { bookings } = useAppContext()
  const { storeCode } = useParams<{
    storeCode: string
  }>()

  return (
    <MenuWrapper open={open}>
      {bookings?.map((booking) => {
        return (
          <Link
            key={`side-nav__${booking.bookingId}`}
            to={`/${storeCode}/${booking.bookingId}`}
            style={{
              textDecoration: 'none'
            }}
          >
            <Box
              borderBottom="solid 1px"
              borderColor="primary"
              paddingY="major-4"
            >
              <Heading use="h3" color="primary">
                {booking.eventType === SAUSAGE_SIZZLE_EVENT
                  ? 'Sausage Sizzle'
                  : booking.name}
              </Heading>

              <Text fontSize="1rem" color="primary">
                {booking.vendor.name}
              </Text>
            </Box>
          </Link>
        )
      })}
    </MenuWrapper>
  )
}

export function Header({
  hideMenu = false,
  showLogo = true
}: {
  hideMenu?: boolean
  showLogo?: boolean
}) {
  const { storeCode } = useParams<{
    storeCode: string
  }>()
  const [menuOpen, setMenuOpen] = useState(false)
  const { site, booking, bookings } = useAppContext()

  return (
    <>
      <Container>
        <Flex flex="1">
          {showLogo && (
            <Link to={`/${storeCode}`}>
              <SausageSizzleLogo />
            </Link>
          )}
          <TitleContainer>
            <Title>
              {get(site, 'name', '') && <>Bunnings {get(site, 'name', '')}</>}
            </Title>
            <SubTitle>{get(booking, 'vendor.name', '')}</SubTitle>
          </TitleContainer>
        </Flex>

        {!hideMenu && bookings && bookings?.length > 1 && (
          <Box>
            <Button
              width="50px"
              variant="link"
              marginLeft="auto"
              padding="0"
              paddingRight="minor-1"
              position="relative"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <CloseIcon /> : <BurgerIcon />}
            </Button>
          </Box>
        )}
      </Container>

      <Menu open={menuOpen} />
    </>
  )
}
