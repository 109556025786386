import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled, ProgressBar } from 'bumbag'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  flex-shrink: 0;
  padding: 0 0.875rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.div<{ highlighted: boolean }>`
  font-size: 0.75rem;
  font-weight: ${(props) => (props.highlighted ? '700' : '500')};
  flex: 1;
  text-align: center;
  margin-top: 0.25rem;
  opacity: ${(props) => (props.highlighted ? 1 : 0.5)};
  color: #0d5257;
`

const Progress = ({
  value,
  refunded,
  stage
}: {
  value: number
  refunded?: boolean
  stage: number
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <ProgressBar value={value} size="small" height="0.375rem" />
      <Row>
        <Text highlighted={stage === 0}>{t('progress.order')}</Text>
        <Text highlighted={stage === 1}>{t('progress.pay')}</Text>
        <Text highlighted={stage === 2}>
          {refunded ? t('progress.refunded') : t('progress.enjoy')}
        </Text>
      </Row>
    </Container>
  )
}

export default Progress
