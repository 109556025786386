import { HttpLink, ApolloClient, InMemoryCache, split } from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_PAYMENT_URL}/graphql`
})

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_PAYMENT_WS_URL}/graphql`,
  options: {
    reconnect: true
  }
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export default client
