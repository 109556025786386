import { styled, Button } from 'bumbag'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Body = styled.div`
  flex: 1;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
`
export const StyledButton = styled(Button)`
  padding: 0.75rem;
  width: 100%;
  margin-top: 1rem;
  border: none;
  color: #0c0801;
  flex-shrink: 0;
`
export const StyledLink = styled.a`
  color: #757575;
  font-weight: normal;
  font-family: Helvetica Neue;
  &:focus {
    outline: none;
  }
`
export const GhostButton = styled(Button)`
  margin-top: 0.5rem;
  border: none;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`
export const Message = styled.div<{ fontSize?: string; fontWeight?: string }>`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  margin-top: 1rem;
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-direction: column;
`
export const Icon = styled.div``
