import React from 'react'
import { styled } from 'bumbag'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getPrivacyByStoreCode } from '../utils/project'

const Container = styled.div`
  width: 20rem;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex-direction: column;

  flex-shrink: 0;
  padding: 0.5rem;
  text-align: center;
`

const PrivacyLink = styled.a`
  font-family: Helvetica Neue;
  color: #0d5257;
  opacity: 0.7;
  &:focus {
    outline: none;
  }
`

const Privacy = () => {
  const { t } = useTranslation()
  const { storeCode } = useParams<{ storeCode: string }>()
  const link = getPrivacyByStoreCode(storeCode)
  return (
    <Container>
      <span>
        {t('privacy.text')}{' '}
        <PrivacyLink href={link} target="_blank" rel="noopener noreferrer">
          {t('privacy.link')}
        </PrivacyLink>
        .
      </span>
    </Container>
  )
}

export default Privacy
