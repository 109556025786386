import React from 'react'
import { Image, Button, Icon, styled, palette } from 'bumbag'
import { useTranslation } from 'react-i18next'

type Props = {
  imageSrc?: string
  disabled: boolean
  price: string
  quantity: number
  name: string
  reachLimit: boolean
  desc: string
  handleAdd: () => void
  handleRemove: () => void
}

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-top: 0.5rem;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  padding: 0.5rem;
  &:first-of-type {
    margin-top: 0;
  }
  &:first-of-type {
    margin-bottom: 0.5rem;
  }
`
const ImageContainer = styled.div`
  border-radius: 0.625rem;
  background-color: rgba(13, 82, 87, 0.08);
  padding: 0.5rem;
  height: 6.875rem;
  width: 6.875rem;
  text-align: center;
`
const Price = styled.div`
  font-weight: bold;
  color: ${palette('primary')};
  font-size: 1.25rem;
`
const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  padding-left: 1rem;
  min-height: 6.875rem;
  flex-direction: column;
  justify-content: space-between;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Quantity = styled.div`
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  font-weight: 500;
`
const StyledIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`
const StyledButton = styled(Button)`
  border-radius: 50%;
  box-shadow: 0 0 4px 0 rgba(13, 82, 87, 0.2);
  border: unset;
  &:focus {
    box-shadow: none;
  }
  &:enabled {
    &:hover {
      & > svg {
        color: #fff !important;
      }
      background-color: ${palette('primary')};
      &:active {
        background-color: ${palette('primary')};
        & > svg {
          color: #fff !important;
        }
      }
    }
  }
  &:first-of-type {
    margin-right: 2rem;
  }
`
const Description = styled.div`
  font-size: 0.875rem;
  font-family: Helvetica Neue;
  margin-bottom: 0.25rem;
`
const ItemSelector = ({
  imageSrc,
  disabled,
  price,
  quantity,
  name,
  desc,
  reachLimit,
  handleAdd,
  handleRemove
}: Props) => {
  const { t } = useTranslation()
  return (
    <Container>
      <ImageContainer>
        {imageSrc && <Image src={imageSrc} height="3.5rem" alt="item-image" />}
        <Price>
          {t('currencySymbol')}
          {price}
        </Price>
      </ImageContainer>
      <ButtonContainer>
        <Quantity>
          {quantity > 0 ? `${quantity} x` : ''} {name}
        </Quantity>
        {desc && <Description>{desc}</Description>}
        <ButtonGroup>
          <StyledButton
            onClick={handleRemove}
            disabled={disabled}
            height="3.125rem"
            width="3.125rem"
            minHeight="3.125rem"
            data-testid={`remove-item-button-${name.toLowerCase()}`}
            icon-before="solid-minus"
            margin-right="2rem"
          >
            <StyledIcon icon="solid-minus" color="primary" />
          </StyledButton>
          <StyledButton
            onClick={handleAdd}
            disabled={reachLimit}
            height="3.125rem"
            width="3.125rem"
            minHeight="3.125rem"
            data-testid={`add-item-button-${name.toLowerCase()}`}
          >
            <StyledIcon icon="solid-plus" color="primary" />
          </StyledButton>
        </ButtonGroup>
      </ButtonContainer>
    </Container>
  )
}

export default ItemSelector
