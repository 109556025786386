import React from 'react'
import { styled, Image } from 'bumbag'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  flex: 1 0 auto;
  justify-content: flex-end;
`
const Text = styled.div`
  font-family: Helvetica Neue;
  font-weight: normal;
`
const Row = styled.div`
  display: flex;
  font-size: 1.1875rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`
const StyledImage = styled(Image)`
  height: 2.5rem;
  margin-right: 1rem;
`

const Instruction = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Row>
        <StyledImage
          src={`${process.env.PUBLIC_URL}/images/summary.svg`}
          alt={'instruction-image'}
        />
        {t('summary.instruction.title')}
      </Row>
      <Text>{t('summary.instruction.text')}</Text>
    </Container>
  )
}

export default Instruction
