import { styled } from 'bumbag'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Body = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  overflow: auto;
  align-self: center;
`
export const DonationBody = styled(Body)`
  width: 100%;
  flex: 1 0 auto;
  max-width: 50rem;
  justify-content: center;
`

export const ItemSelectorContainer = styled.div`
  width: 100%;
  flex: 1 0 auto;
  padding: 0 1rem;
`
export const NoBooking = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  padding-bottom: 1rem;
`
export const ProgressContainer = styled.div`
  padding: 0 1rem;
`
export const Message = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
`
