import React, { FC } from 'react'
import { styled } from 'bumbag'
import { useTranslation } from 'react-i18next'

import Privacy from '../Privacy'
import { CENTS } from '../../constants'

const Container = styled.div`
  background-color: #fff;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(13, 82, 87, 0.2);
  position: relative;
  flex-shrink: 0;
`
const OrderSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 4rem;
`
const OrderSummaryWithWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
`
const OrderSummary = styled.div`
  display: flex;
  width: 100%;
`
const OrderSummaryText = styled.div`
  font-size: 1.25rem;
  display: flex;
  align-items: center;
`
const OrderSummaryPrice = styled.div`
  flex: 1;
  font-size: 1.75rem;
  text-align: right;
  color: #757575;
  font-weight: bold;
`

const Warning = styled.div`
  font-size: 0.75rem;
  color: #da291c;
  width: 100%;
  text-align: right;
  height: 1.125rem;
`

const OrderDetails: FC<{
  reachLimit: boolean
  totalPrice: number
  checkoutLimit: number
}> = ({ reachLimit, totalPrice, checkoutLimit, children }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <OrderSummaryContainer>
        <OrderSummaryWithWarning>
          <OrderSummary>
            <OrderSummaryText>{t('order.total')}</OrderSummaryText>
            <OrderSummaryPrice data-testid="total-price">
              {t('currencySymbol')} {totalPrice.toFixed(CENTS)}
            </OrderSummaryPrice>
          </OrderSummary>
          {reachLimit && (
            <Warning>
              ${checkoutLimit} {t('order.reachLimit')}
            </Warning>
          )}
        </OrderSummaryWithWarning>
        {children}
      </OrderSummaryContainer>
      <Privacy />
    </Container>
  )
}

export default OrderDetails
