import React, { useRef, useState } from 'react'
import { Box, Button, Text, InputField, Flex } from 'bumbag'
import {
  useAppContext,
  useAppContextActions
} from '../../../context/App/AppContext'
import { DONATION_AMOUNT_DEFAULTS } from './constants'
import { Body, DonationBody } from '../styled-components'
import { useTranslation } from 'react-i18next'
import OrderDetails from '../../../components/order/OrderDetails'
import { CENTS } from '../../../constants'
import { getPurchaseLimit } from '../../../utils/purchaseLimit'
import { TBooking } from '../../../types'
import { Header } from '../../../components/Header'
import { getCountryByProjectId } from '../../../utils/project'
import { removeDonationFromLocalStorage } from '../../../utils/order'
interface DonationsProps {
  closeDonations: () => void
}

function getTaxId({
  vendor,
  isRedRun,
  isCharity
}: {
  vendor?: TBooking['vendor']
  isRedRun: boolean
  isCharity: boolean
}) {
  if ((!isRedRun && !isCharity) || !vendor) {
    return {
      type: undefined,
      value: undefined,
      label: ''
    }
  }

  if (vendor.taxId?.value && vendor.taxId?.type) {
    const label = `, ${vendor.taxId.type.split('_')[1].toUpperCase()}: ${
      vendor.taxId.value
    }`

    return {
      type: vendor.taxId.type,
      value: vendor.taxId.value,
      label
    }
  }

  return {
    type: undefined,
    value: undefined,
    label: ''
  }
}

function getName({ booking }: { booking: TBooking | undefined }) {
  if (!booking) {
    return ''
  }

  if (!booking.isRedRun && !booking.vendor?.isCharity) {
    return booking?.vendor?.name
  }

  if (
    (booking.isRedRun || booking.vendor?.isCharity) &&
    booking?.vendor?.name &&
    booking.vendor.altName
  ) {
    return `${booking.vendor.name}, ${booking.vendor.altName}`
  }
  if (booking?.vendor?.name) {
    return booking?.vendor?.name
  }

  return ''
}

function getGiftDonationStringPath({
  donationOrGift,
  projectId
}: {
  projectId: string
  donationOrGift: 'donation' | 'gift'
}) {
  const country = getCountryByProjectId(projectId)

  if (country === 'nz') {
    return `gift.taxDeductible.nz`
  }

  return `${donationOrGift}.taxDeductible.au`
}

const numberValidation = /^(\d+\.?\d{0,2})?$/

export function Donations({ closeDonations }: DonationsProps) {
  const { t } = useTranslation()

  const {
    donation,
    booking,
    totalPrice,
    donationOrGift,
    project
  } = useAppContext()
  const bookingId = booking?.bookingId as string
  const { setDonation } = useAppContextActions()

  const [totalPriceWithoutDonation] = useState<number>(
    Number(totalPrice) - Number(donation || 0)
  )

  const amountInputRef = useRef()
  const [donationInput, setDonationInput] = useState<string>(
    donation?.toFixed(CENTS) ?? ''
  )

  const onAddDonation = (value?: string) => {
    setDonation?.(Number(value ?? donationInput))
    closeDonations()
  }

  const onRemove = () => {
    setDonation(undefined)

    removeDonationFromLocalStorage({
      bookingId
    })
    closeDonations()
  }

  const proposedTotalPrice = totalPriceWithoutDonation + Number(donationInput)

  const purchaseLimit = getPurchaseLimit(booking)
  const reachLimit = proposedTotalPrice > purchaseLimit

  const projectId = project?.projectId as string

  const taxId = getTaxId({
    vendor: booking?.vendor,
    isRedRun: Boolean(booking?.isRedRun),
    isCharity: Boolean(booking?.vendor?.isCharity)
  })

  return (
    <>
      <Header />

      <Body>
        <DonationBody>
          <Box textAlign="center">
            <Flex alignItems="center" flexDirection="column">
              <Text fontWeight="900" fontSize="20px" textAlign="left">
                {t(`${donationOrGift}.title`)}
              </Text>
            </Flex>

            <Text color="lightGrey">
              {t(`${donationOrGift}.subHeading`)}
              <Text use="div" textTransform="capitalize">
                {getName({ booking })}
                {taxId?.label}
              </Text>
            </Text>

            <Text
              textAlign="center"
              use="div"
              color="lightGrey"
              padding="major-1"
              paddingTop="major-2"
            >
              {t(getGiftDonationStringPath({ donationOrGift, projectId }))}
            </Text>

            <Box
              display="flex"
              padding="major-2"
              justifyContent="space-evenly"
              width="100%"
            >
              {DONATION_AMOUNT_DEFAULTS.map((donationAmount) => {
                return (
                  <Button
                    key={donationAmount}
                    width="auto"
                    flexGrow="1"
                    margin="0.25rem"
                    onClick={() => {
                      setDonationInput(donationAmount.toFixed(CENTS))
                    }}
                  >
                    {t('currencySymbol')} {donationAmount.toFixed(CENTS)}
                  </Button>
                )
              })}
            </Box>

            <InputField
              inputProps={{
                textAlign: 'center',
                ref: amountInputRef,
                paddingRight: '3.5rem',
                position: 'relative'
              }}
              value={donationInput ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!numberValidation.test(e.target.value)) return
                setDonationInput(e.target.value ?? '')
              }}
              placeholder="Add different amount"
              margin="major-2"
              marginTop={'0rem'}
              addonBefore={<Button isStatic>{t('currencySymbol')}</Button>}
            />

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginLeft="major-2"
              marginRight="major-2"
            >
              <Button
                palette="yellow"
                margin="1rem"
                color="black"
                alignItems="center"
                width={'70%'}
                onClick={() => onAddDonation()}
                disabled={reachLimit}
              >
                {donation
                  ? t(`${donationOrGift}.button.update`)
                  : t(`${donationOrGift}.button.add`)}
              </Button>
              <Box display="flex" flexDirection="row">
                <Button
                  variant="link"
                  onClick={closeDonations}
                  textDecoration="underline"
                  color="lightGrey"
                >
                  {t(`${donationOrGift}.button.cancel`)}
                </Button>

                {donation ? (
                  <Button
                    variant="link"
                    onClick={onRemove}
                    textDecoration="underline"
                    color="lightGrey"
                  >
                    {t(`${donationOrGift}.button.remove`)}
                  </Button>
                ) : null}
              </Box>
            </Box>
          </Box>
        </DonationBody>

        <OrderDetails
          reachLimit={reachLimit}
          totalPrice={proposedTotalPrice}
          checkoutLimit={purchaseLimit}
        />
      </Body>
    </>
  )
}
