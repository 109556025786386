import React from 'react'
import { styled, Image, palette, keyframes } from 'bumbag'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { isEmpty, get, partition } from 'lodash'
import { TOrderDetails } from '../../types'
import { CENTS, PRODUCT_IMAGE_URL, SAUSAGE_SIZZLE_EVENT } from '../../constants'
import { useAppContext } from '../../context/App'

type Props = {
  order?: TOrderDetails
  isOrderCompletedOrRefunded?: boolean
}

const Container = styled.div<{
  isOrderCompletedOrRefunded?: boolean | undefined
  hasDonation?: boolean
}>`
  border-radius: 8px;
  border-bottom-right-radius: ${({ hasDonation }) =>
    hasDonation ? '0px' : '8px'};
  border-bottom-left-radius: ${({ hasDonation }) =>
    hasDonation ? '0px' : '8px'};
  border: solid 1px rgba(151, 151, 151, 0.26);
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  opacity: ${(props) => (props.isOrderCompletedOrRefunded ? 0.7 : 1)};
`

const DonationContainer = styled(Container)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: hidden;
  justify-content: center;
  display: flex;
  flex-direction: row;
  font-weight: 700;
`

const DonationText = styled.span`
  color: #333333;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const DateTimeAndOrderNumber = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
`
const Product = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;
`
const Quantity = styled.div`
  font-size: 1.75rem;
  color: ${palette('primary')};
  font-weight: 500;
  display: flex;
  align-items: flex-end;
`
const Name = styled.div<{
  hasImage?: boolean
}>`
  flex: 1;
  font-size: 1.4375rem;
  color: ${palette('primary')};
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  ${(props) => props.hasImage && 'padding-left: 1.5rem;'}
`

const rotate = keyframes`
  0 {
    transform: rotate(0);
  }
  25%{
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
`
const ProductImageContainer = styled.div`
  height: 3.5rem;
  width: 3.5rem;
  padding: 0.5rem;
`
const StyledImage = styled(Image)`
  height: 2.5rem;
  animation: ${rotate} 0.4s linear infinite;
`

const CoinsImage = styled(Image)`
  height: 1.5rem;
  margin-right: 0.5rem;
`

const Time = styled.span`
  color: #da291c;
`

const GIFT = 'gift'
const DONATION = 'donation'

const ITEMS_WITH_IMAGE = ['sausage', 'drink']
const OrderCard = ({ order, isOrderCompletedOrRefunded }: Props) => {
  const { t } = useTranslation()
  const { donationOrGift, booking } = useAppContext()

  /*
   * Gifts and donations will start with `Gift` or `Donation` followed by the vendor's name
   * We need to remove them from the order items and display them separately
   */

  const [donations, orderItems] = partition(order?.items, (item) => {
    return (
      item.name.toLowerCase().includes(GIFT) ||
      item.name.toLowerCase().includes(DONATION)
    )
  })

  const donation = get(donations, '[0].totalAmount')

  return (
    <>
      <Container
        isOrderCompletedOrRefunded={isOrderCompletedOrRefunded}
        hasDonation={!!order?.donation}
      >
        <Row>
          <DateTimeAndOrderNumber>
            {t('summary.orderNumber')}{' '}
            {get(order, 'orderNumber', '').substring(2)}
          </DateTimeAndOrderNumber>
          <DateTimeAndOrderNumber>
            {dayjs(get(order, 'orderDate')).format('D MMM')}{' '}
            <Time>{dayjs(get(order, 'orderDate')).format('h:mma')}</Time>
          </DateTimeAndOrderNumber>
        </Row>
        {!isEmpty(orderItems) &&
          orderItems.map(({ name, quantity }) => {
            const hasImage = ITEMS_WITH_IMAGE.includes(name.toLowerCase())

            return (
              <Product key={name}>
                {hasImage && (
                  <ProductImageContainer>
                    <StyledImage
                      src={`${PRODUCT_IMAGE_URL}/${name}.png`}
                      alt={`order-card-${name}`}
                    />
                  </ProductImageContainer>
                )}
                <Name hasImage={hasImage}>{name}</Name>
                <Quantity>{quantity}</Quantity>
              </Product>
            )
          })}
        {donation && booking?.eventType !== SAUSAGE_SIZZLE_EVENT && (
          <Product>
            <Name>Order payment</Name>
            <Quantity>${(donation / 100).toFixed(CENTS)} </Quantity>
          </Product>
        )}
      </Container>
      {donation && booking?.eventType === SAUSAGE_SIZZLE_EVENT && (
        <DonationContainer
          isOrderCompletedOrRefunded={isOrderCompletedOrRefunded}
        >
          <CoinsImage
            src={`${process.env.PUBLIC_URL}/images/coins.svg`}
            alt={'donation-image'}
          />
          <DonationText>
            ${(donation / 100).toFixed(CENTS)}{' '}
            {t(`${donationOrGift}.button.added`)}
          </DonationText>
        </DonationContainer>
      )}
    </>
  )
}

export default OrderCard
