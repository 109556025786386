import { styled, Button } from 'bumbag'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const Body = styled.div`
  flex: 1;
  height: auto;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
`
export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const Text = styled.div`
  color: #757575;
  font-size: 1.25rem;
  text-align: center;
  padding: 0 2rem;
  margin-bottom: 2rem;
`
export const StyledButton = styled(Button)`
  padding: 0.75rem;
  width: 100%;
  margin-top: 0.75rem;
  border: none;
  color: #fff;
`
export const Warning = styled.div`
  font-size: 0.75rem;
  color: #da291c;
  width: 100%;
  text-align: center;
  height: 1.125rem;
`
