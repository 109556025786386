import { styled, Button } from 'bumbag'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
export const Body = styled.div`
  flex: 1;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
`
export const StyledButton = styled(Button)`
  padding: 0.75rem;
  width: 100%;
  margin-top: 2rem;
  border: none;
  color: #0c0801;
  flex-shrink: 0;
`
export const ModalText = styled.div`
  color: #000;
  text-align: center;
  font-family: Helvetica Neue;
`
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`
export const ModalButton = styled(Button)`
  margin-top: 1rem;
  padding: 0.75rem;
  &:hover {
    background-color: #44af69;
    color: #fff;
    &:active {
      color: #fff;
      background-color: #44af69;
    }
  }
  &:focus {
    color: #fff;
    background-color: #44af69;
  }
`
export const GhostButton = styled(Button)`
  margin-top: 0.5rem;
  border: none;
  color: #757575;
  font-weight: normal;
  text-decoration: underline;
  font-family: Helvetica Neue;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    background-color: transparent;
  }
`
export const StyledLink = styled.a`
  color: #757575;
  font-weight: normal;
  font-family: Helvetica Neue;
  &:focus {
    outline: none;
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
`
