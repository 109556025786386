import {
  faPlus,
  faMinus,
  faDollarSign,
  faExclamationCircle as faExclamationCircleSolid
} from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import {
  faUsdCircle,
  faExclamationCircle
} from '@fortawesome/pro-regular-svg-icons'
import { ThemeConfig, css } from 'bumbag'
export const CENTS = 2
export const COUNTRIES = {
  au: 'AU',
  nz: 'NZ'
}

export const ROUTES = {
  login: '/',
  app: '/:storeCode',
  booking: '/:storeCode/:bookingId',
  order: '/:storeCode/',
  summary: '/:storeCode/:bookingId/summary',
  completed: '/:storeCode/:bookingId/completed'
}

export const I18N = {
  nameSpace: 'translation',
  defaultLng: 'en'
}

export const LOCAL_STORAGE_ORDER = 'localz-sausage-sizzle-order'
export const LOCAL_STORAGE_EXPIRATION_TIME = 10 // 10 hours
export const CHECKOUT_LIMIT = 100_00

export const ORDER_STATUS = {
  completed: 'COMPLETE'
}
export const REFUND_STATUS = {
  succeeded: 'succeeded'
}
export const SAUSAGE_SIZZLE_EVENT = 'Sausage sizzle'

export const NON_SIZZLE_EVENT_TYPE = 'Non sizzle'

export const PRODUCT_IMAGE_URL =
  'https://webapp-assets-master.localz.io/omw/4lAgXXJTFOxUEVyhdS4v5leRcxiASyQ3Z3D96000'

export const PRIVACY_LINKS = {
  au: 'https://www.bunnings.com.au/privacy-policy',
  nz: 'https://www.bunnings.co.nz/privacy-policy'
}

export const BUMBAG_THEME: ThemeConfig = {
  global: {
    styles: {
      base: css`
        #root > div {
          height: 100%;
        }
        html,
        body {
          height: 100%;
          color: #0d5257;
          touch-action: manipulation;
        }
        #root {
          height: 100%;
          color: #0d5257;
          touch-action: manipulation;
          background-color: #fafafe;
        }
        .react-code-input > input:focus {
          outline: none;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      `
    }
  },
  palette: {
    primary: '#0D5257',
    secondary: '#da291c',
    warning: '#ffac00',
    white: '#ffffff',
    yellow: '#FFAB00',
    yellow600: '#e69a00',
    yellow700: '#e69a00',
    yellow200: '#e69a00',
    lightGrey: '#757575',
    black: '#222222'
  },
  icons: {
    iconSets: [
      {
        icons: [faThumbsUp, faUsdCircle, faExclamationCircle],
        prefix: 'regular-',
        type: 'font-awesome'
      },
      {
        icons: [faPlus, faMinus, faDollarSign, faExclamationCircleSolid],
        prefix: 'solid-',
        type: 'font-awesome'
      }
    ]
  },
  Dialog: {
    Content: {
      styles: {
        base: css`
          padding: 2rem;
        `
      }
    }
  },
  ProgressBar: {
    Indicator: {
      styles: {
        base: css`
          border-radius: 3px;
        `
      }
    }
  }
}

export const STORE_CODE_MAPPING = [
  { storeCode: '8196', country: 'AU', projectStoreCode: '4110' },
  { storeCode: '5226', country: 'AU', projectStoreCode: '5950' },
  { storeCode: '8467', country: 'AU', projectStoreCode: '4802' },
  { storeCode: '6436', country: 'AU', projectStoreCode: '2640' },
  { storeCode: '7313', country: 'AU', projectStoreCode: '2015' },
  { storeCode: '2325', country: 'AU', projectStoreCode: '0870' },
  { storeCode: '6404', country: 'AU', projectStoreCode: '3018' },
  { storeCode: '2432', country: 'AU', projectStoreCode: '0028' },
  { storeCode: '7303', country: 'AU', projectStoreCode: '2350' },
  { storeCode: '7174', country: 'AU', projectStoreCode: '2064' },
  { storeCode: '8158', country: 'AU', projectStoreCode: '4214' },
  { storeCode: '7175', country: 'AU', projectStoreCode: '2131' },
  { storeCode: '8470', country: 'AU', projectStoreCode: '4883' },
  { storeCode: '2706', country: 'AU', projectStoreCode: '6233' },
  { storeCode: '6247', country: 'AU', projectStoreCode: '3875' },
  { storeCode: '2010', country: 'AU', projectStoreCode: '6021' },
  { storeCode: '2442', country: 'AU', projectStoreCode: '6171' },
  { storeCode: '7304', country: 'AU', projectStoreCode: '2093' },
  { storeCode: '6215', country: 'AU', projectStoreCode: '3350' },
  { storeCode: '8153', country: 'AU', projectStoreCode: '2478' },
  { storeCode: '7086', country: 'AU', projectStoreCode: '2200' },
  { storeCode: '7349', country: 'AU', projectStoreCode: '2536' },
  { storeCode: '7076', country: 'AU', projectStoreCode: '2795' },
  { storeCode: '2445', country: 'AU', projectStoreCode: '6053' },
  { storeCode: '6228', country: 'AU', projectStoreCode: '3153' },
  { storeCode: '4040', country: 'AU', projectStoreCode: '2617' },
  { storeCode: '7368', country: 'AU', projectStoreCode: '2518' },
  { storeCode: '2052', country: 'AU', projectStoreCode: '6104' },
  { storeCode: '7168', country: 'AU', projectStoreCode: '2085' },
  { storeCode: '6174', country: 'AU', projectStoreCode: '3555' },
  { storeCode: '5222', country: 'AU', projectStoreCode: '5343' },
  { storeCode: '8171', country: 'AU', projectStoreCode: '4205' },
  { storeCode: '2015', country: 'AU', projectStoreCode: '0029' },
  { storeCode: '7342', country: 'AU', projectStoreCode: '2148' },
  { storeCode: '7364', country: 'AU', projectStoreCode: '2177' },
  { storeCode: '6035', country: 'AU', projectStoreCode: '3128' },
  { storeCode: '8191', country: 'AU', projectStoreCode: '4500' },
  { storeCode: '6126', country: 'AU', projectStoreCode: '3047' },
  { storeCode: '2210', country: 'AU', projectStoreCode: '6725' },
  { storeCode: '8066', country: 'AU', projectStoreCode: '4118' },
  { storeCode: '6426', country: 'AU', projectStoreCode: '3056' },
  { storeCode: '2179', country: 'AU', projectStoreCode: '6230' },
  { storeCode: '8154', country: 'AU', projectStoreCode: '4670' },
  { storeCode: '8181', country: 'AU', projectStoreCode: '4304' },
  { storeCode: '8172', country: 'AU', projectStoreCode: '4220' },
  { storeCode: '4453', country: 'AU', projectStoreCode: '7320' },
  { storeCode: '2063', country: 'AU', projectStoreCode: '6280' },
  { storeCode: '8170', country: 'AU', projectStoreCode: '2481' },
  { storeCode: '8471', country: 'AU', projectStoreCode: '4870' },
  { storeCode: '8079', country: 'AU', projectStoreCode: '4551' },
  { storeCode: '4058', country: 'AU', projectStoreCode: '2609' },
  { storeCode: '2438', country: 'AU', projectStoreCode: '6107' },
  { storeCode: '8026', country: 'AU', projectStoreCode: '4170' },
  { storeCode: '8101', country: 'AU', projectStoreCode: '4157' },
  { storeCode: '7219', country: 'AU', projectStoreCode: '2229' },
  { storeCode: '7132', country: 'AU', projectStoreCode: '2118' },
  { storeCode: '6386', country: 'AU', projectStoreCode: '3023' },
  { storeCode: '6427', country: 'AU', projectStoreCode: '3201' },
  { storeCode: '8018', country: 'AU', projectStoreCode: '4036' },
  { storeCode: '7314', country: 'AU', projectStoreCode: '2154' },
  { storeCode: '7332', country: 'AU', projectStoreCode: '2325' },
  { storeCode: '6428', country: 'AU', projectStoreCode: '3148' },
  { storeCode: '7216', country: 'AU', projectStoreCode: '2067' },
  { storeCode: '2433', country: 'AU', projectStoreCode: '6010' },
  { storeCode: '6437', country: 'AU', projectStoreCode: '3978' },
  { storeCode: '6310', country: 'AU', projectStoreCode: '3058' },
  { storeCode: '2160', country: 'AU', projectStoreCode: '6164' },
  { storeCode: '7343', country: 'AU', projectStoreCode: '2450' },
  { storeCode: '6429', country: 'AU', projectStoreCode: '3250' },
  { storeCode: '6425', country: 'AU', projectStoreCode: '3066' },
  { storeCode: '7325', country: 'AU', projectStoreCode: '2794' },
  { storeCode: '6385', country: 'AU', projectStoreCode: '3064' },
  { storeCode: '6201', country: 'AU', projectStoreCode: '3977' },
  { storeCode: '7181', country: 'AU', projectStoreCode: '2170' },
  { storeCode: '6118', country: 'AU', projectStoreCode: '3116' },
  { storeCode: '8179', country: 'AU', projectStoreCode: '4405' },
  { storeCode: '6439', country: 'AU', projectStoreCode: '3175' },
  { storeCode: '2320', country: 'AU', projectStoreCode: '0820' },
  { storeCode: '6442', country: 'AU', projectStoreCode: '3356' },
  { storeCode: '4468', country: 'AU', projectStoreCode: '7310' },
  { storeCode: '7080', country: 'AU', projectStoreCode: '2830' },
  { storeCode: '7082', country: 'AU', projectStoreCode: '2158' },
  { storeCode: '6433', country: 'AU', projectStoreCode: '0019' },
  { storeCode: '2437', country: 'AU', projectStoreCode: '6101' },
  { storeCode: '7316', country: 'AU', projectStoreCode: '2036' },
  { storeCode: '6177', country: 'AU', projectStoreCode: '3564' },
  { storeCode: '5228', country: 'AU', projectStoreCode: '5039' },
  { storeCode: '2174', country: 'AU', projectStoreCode: '6069' },
  { storeCode: '6037', country: 'AU', projectStoreCode: '3095' },
  { storeCode: '6401', country: 'AU', projectStoreCode: '3076' },
  { storeCode: '6409', country: 'AU', projectStoreCode: '3551' },
  { storeCode: '7118', country: 'AU', projectStoreCode: '0012' },
  { storeCode: '2022', country: 'AU', projectStoreCode: '6450' },
  { storeCode: '6443', country: 'AU', projectStoreCode: '3078' },
  { storeCode: '8440', country: 'AU', projectStoreCode: '4810' },
  { storeCode: '7341', country: 'AU', projectStoreCode: '2871' },
  { storeCode: '7200', country: 'AU', projectStoreCode: '2428' },
  { storeCode: '6130', country: 'AU', projectStoreCode: '3804' },
  { storeCode: '6221', country: 'AU', projectStoreCode: '3199' },
  { storeCode: '4056', country: 'AU', projectStoreCode: '0014' },
  { storeCode: '5181', country: 'AU', projectStoreCode: '5116' },
  { storeCode: '6196', country: 'AU', projectStoreCode: '3214' },
  { storeCode: '2355', country: 'AU', projectStoreCode: '6530' },
  { storeCode: '8041', country: 'AU', projectStoreCode: '4680' },
  { storeCode: '7345', country: 'AU', projectStoreCode: '2284' },
  { storeCode: '4455', country: 'AU', projectStoreCode: '7010' },
  { storeCode: '7134', country: 'AU', projectStoreCode: '2072' },
  { storeCode: '7336', country: 'AU', projectStoreCode: '2580' },
  { storeCode: '7092', country: 'AU', projectStoreCode: '2460' },
  { storeCode: '7315', country: 'AU', projectStoreCode: '2190' },
  { storeCode: '7379', country: 'AU', projectStoreCode: '2557' },
  { storeCode: '7196', country: 'AU', projectStoreCode: '2680' },
  { storeCode: '4059', country: 'AU', projectStoreCode: '2912' },
  { storeCode: '8190', country: 'AU', projectStoreCode: '4570' },
  { storeCode: '2707', country: 'AU', projectStoreCode: '0030' },
  { storeCode: '6452', country: 'AU', projectStoreCode: '3300' },
  { storeCode: '2016', country: 'AU', projectStoreCode: '6112' },
  { storeCode: '6202', country: 'AU', projectStoreCode: '3915' },
  { storeCode: '6299', country: 'AU', projectStoreCode: '3122' },
  { storeCode: '7385', country: 'AU', projectStoreCode: '2324' },
  { storeCode: '2260', country: 'AU', projectStoreCode: '6008' },
  { storeCode: '6395', country: 'AU', projectStoreCode: '3029' },
  { storeCode: '6420', country: 'AU', projectStoreCode: '3400' },
  { storeCode: '7376', country: 'AU', projectStoreCode: '2171' },
  { storeCode: '8197', country: 'AU', projectStoreCode: '4068' },
  { storeCode: '2404', country: 'AU', projectStoreCode: '6018' },
  { storeCode: '8474', country: 'AU', projectStoreCode: '4860' },
  { storeCode: '7337', country: 'AU', projectStoreCode: '2360' },
  { storeCode: '2435', country: 'AU', projectStoreCode: '6027' },
  { storeCode: '2094', country: 'AU', projectStoreCode: '6076' },
  { storeCode: '2390', country: 'AU', projectStoreCode: '6430' },
  { storeCode: '7395', country: 'AU', projectStoreCode: '2390' },
  { storeCode: '7361', country: 'AU', projectStoreCode: '2780' },
  { storeCode: '8203', country: 'AU', projectStoreCode: '4575' },
  { storeCode: '7202', country: 'AU', projectStoreCode: '2440' },
  { storeCode: '5024', country: 'AU', projectStoreCode: '5067' },
  { storeCode: '8057', country: 'AU', projectStoreCode: '4054' },
  { storeCode: '6240', country: 'AU', projectStoreCode: '3173' },
  { storeCode: '8205', country: 'AU', projectStoreCode: '4610' },
  { storeCode: '4422', country: 'AU', projectStoreCode: '7249' },
  { storeCode: '7319', country: 'AU', projectStoreCode: '2208' },
  { storeCode: '4457', country: 'AU', projectStoreCode: '7050' },
  { storeCode: '7213', country: 'AU', projectStoreCode: '2232' },
  { storeCode: '7122', country: 'AU', projectStoreCode: '2289' },
  { storeCode: '7363', country: 'AU', projectStoreCode: '2263' },
  { storeCode: '8212', country: 'AU', projectStoreCode: '4501' },
  { storeCode: '6431', country: 'AU', projectStoreCode: '3224' },
  { storeCode: '7173', country: 'AU', projectStoreCode: '2141' },
  { storeCode: '6435', country: 'AU', projectStoreCode: '3140' },
  { storeCode: '8139', country: 'AU', projectStoreCode: '2480' },
  { storeCode: '7329', country: 'AU', projectStoreCode: '2790' },
  { storeCode: '8199', country: 'AU', projectStoreCode: '0022' },
  { storeCode: '8201', country: 'AU', projectStoreCode: '4129' },
  { storeCode: '8451', country: 'AU', projectStoreCode: '4740' },
  { storeCode: '2130', country: 'AU', projectStoreCode: '6109' },
  { storeCode: '7088', country: 'AU', projectStoreCode: '2320' },
  { storeCode: '2406', country: 'AU', projectStoreCode: '6090' },
  { storeCode: '2444', country: 'AU', projectStoreCode: '6210' },
  { storeCode: '8164', country: 'AU', projectStoreCode: '4179' },
  { storeCode: '2703', country: 'AU', projectStoreCode: '6285' },
  { storeCode: '6224', country: 'AU', projectStoreCode: '3012' },
  { storeCode: '5095', country: 'AU', projectStoreCode: '5046' },
  { storeCode: '8176', country: 'AU', projectStoreCode: '4558' },
  { storeCode: '7318', country: 'AU', projectStoreCode: '2765' },
  { storeCode: '8152', country: 'AU', projectStoreCode: '4650' },
  { storeCode: '7096', country: 'AU', projectStoreCode: '2756' },
  { storeCode: '6090', country: 'AU', projectStoreCode: '0018' },
  { storeCode: '2157', country: 'AU', projectStoreCode: '6156' },
  { storeCode: '6400', country: 'AU', projectStoreCode: '3194' },
  { storeCode: '8204', country: 'AU', projectStoreCode: '4218' },
  { storeCode: '6422', country: 'AU', projectStoreCode: '3754' },
  { storeCode: '2180', country: 'AU', projectStoreCode: '6056' },
  { storeCode: '6156', country: 'AU', projectStoreCode: '3500' },
  { storeCode: '5121', country: 'AU', projectStoreCode: '5031' },
  { storeCode: '6246', country: 'AU', projectStoreCode: '3082' },
  { storeCode: '7016', country: 'AU', projectStoreCode: '2770' },
  { storeCode: '2170', country: 'AU', projectStoreCode: '6030' },
  { storeCode: '7021', country: 'AU', projectStoreCode: '2575' },
  { storeCode: '5210', country: 'AU', projectStoreCode: '5092' },
  { storeCode: '6363', country: 'AU', projectStoreCode: '3189' },
  { storeCode: '8042', country: 'AU', projectStoreCode: '4506' },
  { storeCode: '7302', country: 'AU', projectStoreCode: '2264' },
  { storeCode: '4467', country: 'AU', projectStoreCode: '7018' },
  { storeCode: '6180', country: 'AU', projectStoreCode: '3931' },
  { storeCode: '6160', country: 'AU', projectStoreCode: '3840' },
  { storeCode: '5026', country: 'AU', projectStoreCode: '5250' },
  { storeCode: '5227', country: 'AU', projectStoreCode: '5290' },
  { storeCode: '8103', country: 'AU', projectStoreCode: '4122' },
  { storeCode: '8476', country: 'AU', projectStoreCode: '8476' },
  { storeCode: '7091', country: 'AU', projectStoreCode: '2850' },
  { storeCode: '5224', country: 'AU', projectStoreCode: '5115' },
  { storeCode: '5028', country: 'AU', projectStoreCode: '5253' },
  { storeCode: '7214', country: 'AU', projectStoreCode: '2567' },
  { storeCode: '7159', country: 'AU', projectStoreCode: '2101' },
  { storeCode: '8140', country: 'AU', projectStoreCode: '4211' },
  { storeCode: '8161', country: 'AU', projectStoreCode: '4006' },
  { storeCode: '5118', country: 'AU', projectStoreCode: '5168' },
  { storeCode: '8027', country: 'AU', projectStoreCode: '4566' },
  { storeCode: '8180', country: 'AU', projectStoreCode: '4509' },
  { storeCode: '4466', country: 'AU', projectStoreCode: '7248' },
  { storeCode: '7063', country: 'AU', projectStoreCode: '2750' },
  { storeCode: '7145', country: 'AU', projectStoreCode: '2500' },
  { storeCode: '6448', country: 'AU', projectStoreCode: '3072' },
  { storeCode: '7384', country: 'AU', projectStoreCode: '2152' },
  { storeCode: '6359', country: 'AU', projectStoreCode: '3168' },
  { storeCode: '6140', country: 'AU', projectStoreCode: '3131' },
  { storeCode: '2402', country: 'AU', projectStoreCode: '6163' },
  { storeCode: '6438', country: 'AU', projectStoreCode: '3167' },
  { storeCode: '7347', country: 'AU', projectStoreCode: '2800' },
  { storeCode: '2708', country: 'AU', projectStoreCode: '6017' },
  { storeCode: '8169', country: 'AU', projectStoreCode: '4210' },
  { storeCode: '8106', country: 'AU', projectStoreCode: '4075' },
  { storeCode: '7351', country: 'AU', projectStoreCode: '2211' },
  { storeCode: '8462', country: 'AU', projectStoreCode: '0026' },
  { storeCode: '6227', country: 'AU', projectStoreCode: '3810' },
  { storeCode: '5160', country: 'AU', projectStoreCode: '5106' },
  { storeCode: '7151', country: 'AU', projectStoreCode: '0015' },
  { storeCode: '2685', country: 'AU', projectStoreCode: '6172' },
  { storeCode: '5215', country: 'AU', projectStoreCode: '5606' },
  { storeCode: '7348', country: 'AU', projectStoreCode: '2444' },
  { storeCode: '6042', country: 'AU', projectStoreCode: '3207' },
  { storeCode: '7309', country: 'AU', projectStoreCode: '2316' },
  { storeCode: '5212', country: 'AU', projectStoreCode: '5082' },
  { storeCode: '7301', country: 'AU', projectStoreCode: '2031' },
  { storeCode: '5150', country: 'AU', projectStoreCode: '5161' },
  { storeCode: '6432', country: 'AU', projectStoreCode: '3134' },
  { storeCode: '8211', country: 'AU', projectStoreCode: '4226' },
  { storeCode: '7071', country: 'AU', projectStoreCode: '2216' },
  { storeCode: '8477', country: 'AU', projectStoreCode: '4701' },
  { storeCode: '2163', country: 'AU', projectStoreCode: '6168' },
  { storeCode: '8087', country: 'AU', projectStoreCode: '4106' },
  { storeCode: '7374', country: 'AU', projectStoreCode: '2029' },
  { storeCode: '6075', country: 'AU', projectStoreCode: '3939' },
  { storeCode: '8076', country: 'AU', projectStoreCode: '4022' },
  { storeCode: '7310', country: 'AU', projectStoreCode: '2155' },
  { storeCode: '7333', country: 'AU', projectStoreCode: '2116' },
  { storeCode: '6419', country: 'AU', projectStoreCode: '3850' },
  { storeCode: '6011', country: 'AU', projectStoreCode: '3179' },
  { storeCode: '5211', country: 'AU', projectStoreCode: '0027' },
  { storeCode: '7212', country: 'AU', projectStoreCode: '2147' },
  { storeCode: '7377', country: 'AU', projectStoreCode: '2527' },
  { storeCode: '6412', country: 'AU', projectStoreCode: '3630' },
  { storeCode: '7335', country: 'AU', projectStoreCode: '2330' },
  { storeCode: '7369', country: 'AU', projectStoreCode: '2164' },
  { storeCode: '8466', country: 'AU', projectStoreCode: '4878' },
  { storeCode: '8129', country: 'AU', projectStoreCode: '2486' },
  { storeCode: '8035', country: 'AU', projectStoreCode: '4215' },
  { storeCode: '8182', country: 'AU', projectStoreCode: '4300' },
  { storeCode: '6403', country: 'AU', projectStoreCode: '3171' },
  { storeCode: '8056', country: 'AU', projectStoreCode: '4053' },
  { storeCode: '6411', country: 'AU', projectStoreCode: '3429' },
  { storeCode: '6083', country: 'AU', projectStoreCode: '3020' },
  { storeCode: '6423', country: 'AU', projectStoreCode: '3585' },
  { storeCode: '7312', country: 'AU', projectStoreCode: '2340' },
  { storeCode: '7094', country: 'AU', projectStoreCode: '2430' },
  { storeCode: '6194', country: 'AU', projectStoreCode: '3038' },
  { storeCode: '6390', country: 'AU', projectStoreCode: '3074' },
  { storeCode: '7152', country: 'AU', projectStoreCode: '2120' },
  { storeCode: '8200', country: 'AU', projectStoreCode: '4012' },
  { storeCode: '8195', country: 'AU', projectStoreCode: '4350' },
  { storeCode: '8017', country: 'AU', projectStoreCode: '0023' },
  { storeCode: '6388', country: 'AU', projectStoreCode: '3228' },
  { storeCode: '8437', country: 'AU', projectStoreCode: '4814' },
  { storeCode: '8469', country: 'AU', projectStoreCode: '4818' },
  { storeCode: '6399', country: 'AU', projectStoreCode: '3844' },
  { storeCode: '7148', country: 'AU', projectStoreCode: '2259' },
  { storeCode: '4025', country: 'AU', projectStoreCode: '2900' },
  { storeCode: '7217', country: 'AU', projectStoreCode: '2539' },
  { storeCode: '7344', country: 'AU', projectStoreCode: '2257' },
  { storeCode: '8210', country: 'AU', projectStoreCode: '4119' },
  { storeCode: '7161', country: 'AU', projectStoreCode: '2777' },
  { storeCode: '6230', country: 'AU', projectStoreCode: '3133' },
  { storeCode: '5214', country: 'AU', projectStoreCode: '5211' },
  { storeCode: '8085', country: 'AU', projectStoreCode: '4165' },
  { storeCode: '7179', country: 'AU', projectStoreCode: '2163' },
  { storeCode: '8175', country: 'AU', projectStoreCode: '4014' },
  { storeCode: '7306', country: 'AU', projectStoreCode: '2650' },
  { storeCode: '7323', country: 'AU', projectStoreCode: '2287' },
  { storeCode: '2443', country: 'AU', projectStoreCode: '6065' },
  { storeCode: '6300', country: 'AU', projectStoreCode: '3677' },
  { storeCode: '6449', country: 'AU', projectStoreCode: '3820' },
  { storeCode: '7054', country: 'AU', projectStoreCode: '2502' },
  { storeCode: '7130', country: 'AU', projectStoreCode: '2100' },
  { storeCode: '6225', country: 'AU', projectStoreCode: '3280' },
  { storeCode: '8157', country: 'AU', projectStoreCode: '4370' },
  { storeCode: '6185', country: 'AU', projectStoreCode: '3216' },
  { storeCode: '6415', country: 'AU', projectStoreCode: '3030' },
  { storeCode: '6249', country: 'AU', projectStoreCode: '0016' },
  { storeCode: '7321', country: 'AU', projectStoreCode: '2250' },
  { storeCode: '8159', country: 'AU', projectStoreCode: '4305' },
  { storeCode: '2206', country: 'AU', projectStoreCode: '6155' },
  { storeCode: '5220', country: 'AU', projectStoreCode: '5087' },
  { storeCode: '6975', country: 'AU', projectStoreCode: '3690' },
  { storeCode: '6451', country: 'AU', projectStoreCode: '3995' },
  { storeCode: '5068', country: 'AU', projectStoreCode: '5011' },
  { storeCode: '6430', country: 'AU', projectStoreCode: '3730' },
  { storeCode: '8475', country: 'AU', projectStoreCode: '4703' },
  { storeCode: '2181', country: 'AU', projectStoreCode: '6330' },
  { storeCode: '7024', country: 'AU', projectStoreCode: '2280' },
  { storeCode: '7380', country: 'AU', projectStoreCode: '2290' },
  { storeCode: '7334', country: 'AU', projectStoreCode: '2111' },
  { storeCode: '7365', country: 'AU', projectStoreCode: '2526' },
  { storeCode: '6450', country: 'AU', projectStoreCode: '3337' },
  { storeCode: '2182', country: 'AU', projectStoreCode: '6401' },
  { storeCode: '7220', country: 'AU', projectStoreCode: '2541' },
  { storeCode: '7352', country: 'AU', projectStoreCode: '0013' },
  { storeCode: '2315', country: 'AU', projectStoreCode: '0010' },
  { storeCode: '2328', country: 'AU', projectStoreCode: '0830' },
  { storeCode: '8215', country: 'AU', projectStoreCode: '4209' },
  { storeCode: '2400', country: 'AU', projectStoreCode: '6025' },
  { storeCode: '7356', country: 'AU', projectStoreCode: '2593' },
  { storeCode: '9998', country: 'AU', projectStoreCode: '9998' },
  { storeCode: '0034', country: 'AU', projectStoreCode: '0034' },
  { storeCode: '3660', country: 'AU', projectStoreCode: '3660' },
  { storeCode: '3337', country: 'AU', projectStoreCode: '3337' },
  { storeCode: '4341', country: 'AU', projectStoreCode: '4341' },
  { storeCode: '6453', country: 'AU', projectStoreCode: '0033' },
  { storeCode: '0032', country: 'AU', projectStoreCode: '2560' },
  { storeCode: '1367', country: 'AU', projectStoreCode: '3123' },
  { storeCode: '8453', country: 'AU', projectStoreCode: '0035' },
  { storeCode: '7222', country: 'AU', projectStoreCode: '7222' },
  { storeCode: '7346', country: 'AU', projectStoreCode: '7346' },
  { storeCode: '6402', country: 'AU', projectStoreCode: '3108' },
  { storeCode: '7373', country: 'AU', projectStoreCode: '2039' },
  { storeCode: '8386', country: 'AU', projectStoreCode: '4655' },
  { storeCode: '8220', country: 'AU', projectStoreCode: '4510' },
  { storeCode: '7354', country: 'AU', projectStoreCode: '0036' },
  { storeCode: '8350', country: 'AU', projectStoreCode: '4178' },
  { storeCode: '9999', country: 'NZ', projectStoreCode: '9999' },
  { storeCode: '9484', country: 'NZ', projectStoreCode: '7201' },
  { storeCode: '9453', country: 'NZ', projectStoreCode: '2013' },
  { storeCode: '9532', country: 'NZ', projectStoreCode: '8051' },
  { storeCode: '9515', country: 'NZ', projectStoreCode: '9012' },
  { storeCode: '9439', country: 'NZ', projectStoreCode: '4702' },
  { storeCode: '9486', country: 'NZ', projectStoreCode: '4010' },
  { storeCode: '9491', country: 'NZ', projectStoreCode: '0629' },
  { storeCode: '9518', country: 'NZ', projectStoreCode: '1021' },
  { storeCode: '9527', country: 'NZ', projectStoreCode: '3204' },
  { storeCode: '9529', country: 'NZ', projectStoreCode: '0021' },
  { storeCode: '9498', country: 'NZ', projectStoreCode: '0025' },
  { storeCode: '9411', country: 'NZ', projectStoreCode: '0405' },
  { storeCode: '9503', country: 'NZ', projectStoreCode: '0230' },
  { storeCode: '9489', country: 'NZ', projectStoreCode: '6022' },
  { storeCode: '9471', country: 'NZ', projectStoreCode: '2104' },
  { storeCode: '9454', country: 'NZ', projectStoreCode: '0017' },
  { storeCode: '9510', country: 'NZ', projectStoreCode: '4310' },
  { storeCode: '9493', country: 'NZ', projectStoreCode: '1060' },
  { storeCode: '9479', country: 'NZ', projectStoreCode: '7011' },
  { storeCode: '9502', country: 'NZ', projectStoreCode: '0600' },
  { storeCode: '9504', country: 'NZ', projectStoreCode: '4340' },
  { storeCode: '9470', country: 'NZ', projectStoreCode: '0632' },
  { storeCode: '9480', country: 'NZ', projectStoreCode: '0020' },
  { storeCode: '9526', country: 'NZ', projectStoreCode: '5012' },
  { storeCode: '9477', country: 'NZ', projectStoreCode: '5024' },
  { storeCode: '9414', country: 'NZ', projectStoreCode: '0011' },
  { storeCode: '9476', country: 'NZ', projectStoreCode: '8011' },
  { storeCode: '9452', country: 'NZ', projectStoreCode: '3015' },
  { storeCode: '9482', country: 'NZ', projectStoreCode: '8083' },
  { storeCode: '9497', country: 'NZ', projectStoreCode: '0993' },
  { storeCode: '9483', country: 'NZ', projectStoreCode: '2112' },
  { storeCode: '9528', country: 'NZ', projectStoreCode: '3330' },
  { storeCode: '9430', country: 'NZ', projectStoreCode: '3119' },
  { storeCode: '9474', country: 'NZ', projectStoreCode: '3200' },
  { storeCode: '9432', country: 'NZ', projectStoreCode: '3420' },
  { storeCode: '9496', country: 'NZ', projectStoreCode: '6011' },
  { storeCode: '9521', country: 'NZ', projectStoreCode: '0614' },
  { storeCode: '9481', country: 'NZ', projectStoreCode: '3120' },
  { storeCode: '9434', country: 'NZ', projectStoreCode: '3620' },
  { storeCode: '9449', country: 'NZ', projectStoreCode: '0024' },
  { storeCode: '9451', country: 'NZ', projectStoreCode: '0140' },
  { storeCode: '9300', country: 'NZ', projectStoreCode: '9300' },
  { storeCode: '9462', country: 'NZ', projectStoreCode: '0505' },
  { storeCode: '7910', country: 'NZ', projectStoreCode: '9540' }
]
