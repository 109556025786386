import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { merge, get, isEmpty } from 'lodash'
import dayjs from 'dayjs'
import locales from './'
import { I18N } from '../constants'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      [I18N.nameSpace]: locales.en
    }
  },
  lng: I18N.defaultLng,
  fallbackLng: I18N.defaultLng,
  interpolation: {
    escapeValue: false
  },
  lowerCaseLng: true
})

export const addResourceBundle = (strings: object) =>
  Object.keys(get(strings, I18N.nameSpace, {})).forEach((language) => {
    const config = get(strings, `${I18N.nameSpace}.${language}`)
    if (!isEmpty(config)) {
      i18n.addResourceBundle(
        language,
        I18N.nameSpace,
        merge({}, get(locales, language, {}), config),
        true,
        true
      )
    }
  })

export const initLanguage = async ({
  languages,
  language
}: {
  languages: string[]
  language: string
}) => {
  try {
    await Promise.all(
      languages.map((lng: string) => import(`dayjs/locale/${lng}.js`))
    )
    await i18n.changeLanguage(language)
    dayjs.locale(language)
  } catch (e) {
    console.log('Failed to init language')
  }
}

export default i18n
