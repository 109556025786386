import { Stripe } from '@stripe/stripe-js'
import { createContext, useContext } from 'react'
import { TOrder, TProduct, TProject, TSite, TBooking, TStep } from '../../types'

export type AppState = {
  project?: TProject
  order?: TOrder
  donation?: number
  openAmount?: Record<string, number>
  productsById: { [productId: string]: TProduct }
  totalPrice?: number
  site?: TSite
  bookings?: TBooking[]
  booking?: TBooking
  loading: boolean
  showError: boolean
  donationOrGift: 'donation' | 'gift'
  step: TStep
  storeCode: string
  stripe?: Stripe | null
}

export type AppAction = {
  setShowError: Function
  setDonation: (donation: number | undefined) => void
  setOpenAmount: ({
    bookingId,
    amount
  }: {
    bookingId: string
    amount: number
  }) => void
  setOrder: (order: TOrder) => void
  setProducts: (productsById: { [productId: string]: TProduct }) => void
  setLoading: (loading: boolean) => void
  setStep: (step: TStep) => void
  setBooking: (booking: TBooking) => void
}

export const AppStateContext = createContext<AppState>({
  productsById: {},
  totalPrice: 0,
  loading: false,
  showError: false,
  donationOrGift: 'gift',
  step: TStep.events,
  storeCode: '',
  stripe: null
})

export const AppActionContext = createContext<AppAction>({
  setShowError: () => {},
  setDonation: (donation: number | undefined) => {},
  setOpenAmount: ({
    bookingId,
    amount
  }: {
    bookingId: string
    amount: number
  }) => {},
  setOrder: (order: TOrder) => {},
  setProducts: (productsById: { [productId: string]: TProduct }) => {},
  setLoading: (loading: boolean) => {},
  setStep: (step: TStep) => {},
  setBooking: (booking: TBooking) => {}
})

export const useAppContext = () => {
  const context = useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useSitesState must be used within a SitesProvider')
  }
  return context
}

export const useAppContextActions = () => {
  const context = useContext(AppActionContext)
  if (context === undefined) {
    throw new Error('useSitesAction must be used within a SitesProvider')
  }
  return context
}
