import { isEmpty, filter, get, find } from 'lodash'
import { CENTS } from '../constants'
import { TProduct, TPrice, TProject } from '../types'

export const getProductById = ({
  products,
  id
}: {
  products: TProduct[]
  id: string
}) => find(products, { id })

export const getProductsWithPrice = ({
  products,
  prices,
  project
}: {
  products: TProduct[]
  prices: TPrice[]
  project: TProject
}) => {
  const currency = project?.currency?.toLowerCase()
  if (isEmpty(products)) {
    return []
  }

  return products.map((product) => ({
    ...product,
    prices: filter(
      prices,
      (price) =>
        get(price, 'product') === get(product, 'id') &&
        !!get(price, 'active') &&
        get(price, 'currency') === currency
    )
  }))
}

export const getProductPrice = (product: TProduct, currency: string = 'aud') =>
  (
    get(
      find(get(product, 'prices', []), { product: product.id, currency }),
      'unit_amount',
      0
    ) / 100
  ).toFixed(CENTS)
