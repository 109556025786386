import React, { useEffect, useState, useCallback } from 'react'
import { Spinner, Text, Box, Modal, Button } from 'bumbag'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import qs from 'qs'
import { Header } from '../../components/Header'
import OrderCard from '../../components/summary/OrderCard'
import Privacy from '../../components/Privacy'
import Progress from '../../components/Progress'
import RefundIcon from '../../components/completed/RefundIcon'
import CompletedIcon from '../../components/completed/CompletedIcon'
import * as sdk from '../../sdk'
import { TOrderDetails } from '../../types'
import { sendSentryError } from '../../utils/sentry'
import { useAppContextActions } from '../../context/App/AppContext'
import { ORDER_STATUS, REFUND_STATUS } from '../../constants'
import {
  Container,
  Body,
  StyledButton,
  StyledLink,
  GhostButton,
  Message,
  Icon
} from './styled-components'
import { MistakeModal } from '../../components/modals'

const CompletedSplash = () => {
  const { t } = useTranslation()
  return (
    <Message>
      <Box
        {...{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'flex-end',
          paddingRight: '2rem'
        }}
      >
        <CompletedIcon />
        <Text fontSize="1.1875rem" marginLeft="0.75rem" fontWeight="bold">
          {t('completed.completedText')}
        </Text>
      </Box>
      {t('completed.completedTextContinued')}
    </Message>
  )
}

const RefundSplash = () => {
  const { t } = useTranslation()
  return (
    <Message fontSize={'1.1875rem'} fontWeight="bold">
      <Icon>
        <RefundIcon />
      </Icon>
      {t('completed.refundedText')}
    </Message>
  )
}

const Completed = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { storeCode } = useParams<{ storeCode: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setShowError } = useAppContextActions()
  const [orderDetails, setOrderDetails] = useState<TOrderDetails | undefined>(
    undefined
  )
  const mistakeModal = Modal.useState()

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const orderNumber = get(queryParams, 'orderNumber', '') as string
  const bookingId = get(queryParams, 'bookingId', '') as string
  const projectId = get(queryParams, 'projectId', '') as string
  const isOrderCompleted =
    get(orderDetails, 'orderStatus') === ORDER_STATUS.completed
  const isOrderRefunded =
    get(orderDetails, 'refundStatus') === REFUND_STATUS.succeeded

  const getOrder = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await sdk.getOrder({ orderNumber, bookingId, projectId })
      const order = get(res, 'data.findOrder')
      setOrderDetails(order)
      setIsLoading(false)
      setShowError(false)
    } catch (e) {
      sendSentryError(`Failed to get order (completed) - ${JSON.stringify(e)}`)
      setIsLoading(false)
      setShowError(true)
    }
  }, [orderNumber, bookingId, projectId, setShowError])

  useEffect(() => {
    const order: TOrderDetails | undefined = get(location, 'state.order')
    if (isEmpty(order)) {
      getOrder()
    } else {
      setOrderDetails(order)
    }
  }, [getOrder, location])

  return isLoading ? (
    <Spinner color="primary" size="large" data-testid="completed-spinner" />
  ) : (
    <Container>
      <Header hideMenu />
      <Body>
        <Progress value={100} refunded={isOrderRefunded} stage={3} />
        <OrderCard order={orderDetails!} isOrderCompletedOrRefunded />
        {isOrderRefunded && <RefundSplash />}
        {isOrderCompleted && !isOrderRefunded && <CompletedSplash />}
        <StyledButton
          palette="primary"
          color="#fff"
          onClick={() => {
            history.push(`/${storeCode}?src=reorder`)
          }}
        >
          {t('completed.orderAgainButtonText')}
        </StyledButton>

        {orderDetails?.donation && !orderDetails?.items?.length && (
          <Button
            variant="link"
            onClick={mistakeModal.show}
            paddingTop="major-2"
          >
            <Text
              color="#757575"
              fontWeight="normal"
              fontFamily="Helvetica Neue"
              textDecoration="underline"
            >
              {t('summary.mistakeButtonText')}
            </Text>
          </Button>
        )}
        {(isOrderCompleted || isOrderRefunded) && (
          <GhostButton variant="ghost">
            <StyledLink
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=ziMh2eoOmkiFyrtOPcUejcFAEzL2l_9Bj3qke5M9SgxUMkhaQ01FU1kwUFdHMDZOSVlRQzlaUDFEUiQlQCN0PWcu"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('completed.feedbackButtonButtonText')}
            </StyledLink>
          </GhostButton>
        )}

        {orderDetails?.donation && !orderDetails?.items?.length && (
          <Button
            variant="link"
            onClick={mistakeModal.show}
            paddingTop="major-2"
          >
            <Text
              color="#757575"
              fontWeight="normal"
              fontFamily="Helvetica Neue"
              textDecoration="underline"
            >
              {t('summary.mistakeButtonText')}
            </Text>
          </Button>
        )}

        <MistakeModal
          modal={mistakeModal}
          title={t('summary.modal.refundText')}
        />
        <Privacy />
      </Body>
    </Container>
  )
}

export default Completed
